import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Ecommerce App Template | Eshop App Template | Uizard"
    category="Tablet Templates"
    url="/templates/tablet-templates/e-commerce-tablet-app/"
    metaDescription="Create the perfect e-shop app design for tablets using our e-shop app template. Create your design in minutes with Uizard."
    description="
    h2:Introducing our e-shop web design template optimized for tablet format — e-commerce app design made easy
    <br/>
    E-shop app design template for your tablet made easy. Whether you have a product set you are itching to get to market, or you have a brand idea you want to see visualized in an <a:https://uizard.io/prototyping/>app UI prototype</a>, our e-shop tablet app template design provides you with everything you need to bring your idea to life.
    <br/>
    h3:Create your online shop design with our intuitive tablet template
    <br/>
    With a little bit of Uizard magic, your e-shop tablet app design prototype can be brought to life in minutes. Equipped with everything your design could need, our e-shop template makes the creation and visualization of your app design clean and easy.
    <br/>
    h3:From customizable product display pages to useful CTAs
    <br/>
    To get started, simply open the Uizard app, and drop in the e-shop template. The  <a:https://uizard.io/templates/>UI template</a> comes fully kitted out with all the hero elements you might need, but it's also fully customizable, allowing you to truly turn it into something of your own. Our templates are designed with you in mind, helping you to visualize and realize your ideas on a screen in front of you in minutes.
    "
    pages={[
      "Intuitive home page based on the core best practices of e-commerce Uizardry. Our homepage screen includes all the must-haves when it comes to e-shop apps, ensuring that your homepage to product journey is as smooth as possible",
      "A stunning minimalistic product display page, demonstrating how products will be displayed within your e-shop tablet app design",
      "Lightbox visualization of newsletter sign-ups",
    ]}
    projectCode="RK0e8Lwyq3UWjzYL85RY"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of E-Commerce tablet website: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of E-Commerce tablet website: home page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of E-Commerce tablet website: sign-up to newsletter pop up"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of E-Commerce tablet website: product details"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of E-Commerce website template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/ecommerce-tablet-app/e-commerce-tablet-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/ecommerce-tablet-app/e-commerce-tablet-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/ecommerce-tablet-app/e-commerce-tablet-app-newsletter.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/ecommerce-tablet-app/e-commerce-tablet-app-product-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/ecommerce-tablet-app/e-commerce-tablet-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
